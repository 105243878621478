import gql from "graphql-tag";

let User = {};
User.fragments = {
    attributes: gql`
        fragment UserFragment on User {
            id
            first_name
            last_name
            username
            email
            role
            account_id
            store_id
            status
            enabled
        }
    `,
};

export const QUERY_USER_LIST = gql`
    query listUsers($account_id: String!, $store_id: String) {
        listUsers(account_id: $account_id, store_id: $store_id) {
            ...UserFragment
        }
    }
    ${User.fragments.attributes}
`;

export const QUERY_USER_GET = gql`
    query getUser($user_id: String!) {
        getUser(user_id: $user_id) {
            ...UserFragment
        }
    }
    ${User.fragments.attributes}
`;

export const MUTATION_CREATE_USER = gql`
    mutation createUser($input: UserInput!) {
        createUser(input: $input) {
            ...UserFragment
        }
    }
    ${User.fragments.attributes}
`;

export const MUTATION_UPDATE_USER = gql`
    mutation updateUser($input: UserInput!) {
        updateUser(input: $input) {
            ...UserFragment
        }
    }
    ${User.fragments.attributes}
`;

export const MUTATION_GENERATE_TEMP_PASS = gql`
    mutation generateTempPass($input: UserInput!) {
        generateTempPass(input: $input)
    }
`;
