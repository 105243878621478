import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Badge, Portlet, Table } from "@/components";
import useMeta from "../../base/hooks/useMeta.js";
import {
    MUTATION_GENERATE_TEMP_PASS,
    QUERY_USER_LIST,
} from "../../apollo/module-operations/user";
import { useConfirm, useNotification } from "@/hooks";
import { Button } from "reactstrap";
import UserFormModal from "./UserFormModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";
import GenerateTempPasswordModal from "./GenerateTempPassWord.jsx";

const AccountUsers = ({ toolbar }) => {
    const { user } = useMeta();
    const notify = useNotification();
    const { resourceId, isCreate } = useMeta();
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState(null);
    const [showUserForm, setShowUserForm] = useState(false);
    const [newPassword, setNewPassword] = useState(null);

    const {
        data: stores,
        loading: storeLoading,
        networkStatus,
    } = useQuery(QUERY_USER_LIST, {
        variables: {
            account_id: resourceId,
        },
        skip: isCreate,
    });

    let options = {
        variables: {
            input: {
                email: email,
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    };

    const [mutate] = useMutation(MUTATION_GENERATE_TEMP_PASS, {
        onCompleted: resp => {
            setNewPassword(resp.generateTempPass);

            notify("Password Was Generete Successfully", "success");
        },
        onError: () => {
            notify("Updating error when generating the password", "error");
        },
    });

    const [confirm, launch] = useConfirm(
        () => {
            mutate(options);
        },
        `Are you sure you want to generate the password for the user ${email} ?`,
        null,
        "md",
        "Generate Password",
    );

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "first_name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>{`${row.first_name || ""} ${
                        row.last_name || ""
                    }`}</div>
                );
            },
        },
        {
            dataField: "email",
            text: "Email",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
        },
        {
            dataField: "enabled",
            text: "Status",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "20%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.enabled ? "status__success" : "status__error"
                        }
                        label={row.enabled ? "Active" : "No Active"}
                    />
                );
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setUserId(row.id);
                setShowUserForm(true);
            },
        },
        {
            label: "Generate Password",
            icon: "fas fa-passwd-reset",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setEmail(row.email);
                launch();
            },
        },
    ];

    const createUser = () => {
        setUserId(null);
        setShowUserForm(true);
    };

    const _storeLoading = storeLoading || networkStatus === 4;

    const _users = stores && stores.listUsers;

    return (
        <>
            <Portlet
                header={{
                    title: "Users",
                    subtitle: "All Business Users",
                }}
                sticky
                toolbar={
                    (toolbar,
                    (
                        <Button
                            className="float-right"
                            color="primary"
                            onClick={createUser}
                        >
                            <i className="fas fa-plus" />
                            Create User
                        </Button>
                    ))
                }
            >
                <Table
                    actions={actions}
                    columns={columns}
                    data={_users}
                    keyField="id"
                    loading={_storeLoading}
                    pagination={paginationFactory(paginationOptions)}
                />
                <UserFormModal
                    isOpen={showUserForm}
                    toggle={setShowUserForm}
                    userId={userId}
                />
                <GenerateTempPasswordModal
                    isOpen={newPassword != null}
                    newPassword={newPassword}
                    toggle={setNewPassword}
                />
                {confirm}
            </Portlet>
        </>
    );
};

export default AccountUsers;
