import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section } from "../../base/components/portlet";
import { Col, Row, FormGroup, FormFeedback, Label, Input } from "reactstrap";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_REVERSE_PROXY_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const ReverseProxyForm = ({ app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: inputs,
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "links",
                method: "isEmpty",
                validWhen: false,
                message: "This Link is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Reverse Proxy",
        "",
        MUTATION_UPDATE_REVERSE_PROXY_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Reverse Proxy",
                subtitle: "Reverse Proxy Information",
            }}
            sticky
            toolbar={createOrUpdateButton}
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label>Base Path</Label>
                                    <Input
                                        invalid={validator.links.isInvalid}
                                        name="links"
                                        onChange={inputChange}
                                        placeholder="Base Path"
                                        value={inputs.links}
                                    />
                                    <FormFeedback>
                                        {validator.links.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default ReverseProxyForm;
