import React from "react";
import {
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    Label,
    Button,
    Row,
} from "reactstrap";
import { useNotification } from "@/hooks";
import { Icon } from "@/components";

const GenerateTempPasswordModal = ({ isOpen, toggle, newPassword }) => {
    const notify = useNotification();

    const clipboardCopy = password => {
        navigator.clipboard.writeText(password);
        toggle(null);
        notify(`Password: ${password} is copied`, "success");
    };

    return (
        <Modal isOpen={isOpen} onClosed={() => {}} size="md">
            <ModalHeader>Password Generated</ModalHeader>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-2">
                        <Label>{newPassword}</Label>
                        <Button
                            className="order__table_copy_action"
                            onClick={() => clipboardCopy(newPassword)}
                            style={{ color: "#3b9415" }}
                            title="Copy password generated"
                        >
                            <Icon color="#727a94" icon="copy" />
                        </Button>
                    </FormGroup>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default GenerateTempPasswordModal;
